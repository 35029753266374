// Create a react component that inputs a textarea message then performs a fetch request to localhost:3001 gets back a response as a data.message and displays that message in a box below the textarea

// Path: profgpt/src/App.js
// Compare this snippet from profgpt/index.js:
// // A express server, which will handle api requests coming in and respond back with a json object, it will use body parser as well as cors
// 
// const express = require('express')
// const bodyParser = require('body-parser')
// const cors = require('cors')
// const app = express()
// const port = 3000
// app.use(bodyParser.json())
// app.use(cors())
// 
// // set up a get request to the root of the server
// app.get('/', (req, res) => {
//     // send back a json object
//     res.send('Hello World');
//   });
// app.listen(port, () => console.log(`Listening on port ${port}`))
// 
// Create a react component that inputs a textarea message then performs a fetch request to localhost:3001 gets back a response as a data.message and displays that message in a box below the textarea

import React, { useState } from 'react';
import './App.css';

function App() {

  const [message, setMessage] = useState('');

  const [response, setResponse] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault()
    //fetch('http://localhost:3001/', {
    fetch('https://us-central1-profgpt-1d3f7.cloudfunctions.net/chat', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'
    },
      body: JSON.stringify({ message })
    })
    .then((res) => res.json())
    .then((data) => setResponse(data.message));
  };

  return (
    <div className="App">
      <h1>Correcteur de copie de droit</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Message:
          <textarea
            value={message}
            placeholder="Insère le texte à corriger ici"
            onChange={e => setMessage(e.target.value)}
          />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
      {response && <div><b>Steve:</b>{response}</div>}
    </div>
  );

}

export default App;